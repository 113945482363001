import {
  AccountClaims,
  accountClaimsFrom,
  PlanClaims,
  planClaimsFrom,
} from "@gitboard-io/gitboard-user-service-sdk";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import axios from "axios";
import qs from "qs";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { authEndpoint, redirectUrl, userPool } from "../environment";
import { useGitboardApiSdk } from "./services";
import { useAccounts } from "./UserService";

interface User {
  username: string;
  email: string;
  jwtToken: string;
  refreshToken: string;
  admin: boolean;
}

export const userState = atom<User | undefined>({
  key: "userState",
  default: undefined,
});

export const dashboardState = atom<string | undefined>({
  key: "dashboardState",
  default: undefined,
});

export const jwtTokenQuery = selector<
  | { token: string; claims: AccountClaims; plan: PlanClaims | undefined }
  | undefined
>({
  key: "jwtTokenQuery",
  get: ({ get }) => {
    const user = get(userState);
    if (user) {
      const token = user.jwtToken;
      const claimPayload = JSON.parse(
        atob(token.split(".")[1].replace("-", "+").replace("_", "/")),
      );
      const claims = accountClaimsFrom(claimPayload);
      const plan = planClaimsFrom(claimPayload);
      if (claims) {
        return { token, claims, plan };
      }
    }
    return undefined;
  },
});

export const useJwtToken = () => useRecoilValue(jwtTokenQuery)?.token ?? "";
export const useAccountInfo = () =>
  useRecoilValue(jwtTokenQuery)?.claims ?? {
    userAccount: { id: "", type: "user", access: "none" },
    otherAccounts: [],
  };

export const usePlanInfo = () => useRecoilValue(jwtTokenQuery)?.plan;

export function useSelectedAccount() {
  const accountInfo = useAccountInfo();
  const { account } = useParams();
  return account ?? accountInfo.userAccount.id;
}

export function useSelectedAccountInfo(account: string) {
  const accountInfo = useAccounts();
  return accountInfo.member.find((it) => it.id === account);
}

const extractUserFrom = async (session: CognitoUserSession) => {
  const {
    preferred_username: username,
    identities,
    email,
    "cognito:groups": groups,
  } = session.getIdToken().payload;
  return {
    username: identities?.length
      ? identities[0].userId
      : username.toLowerCase(),
    email: email.toLowerCase(),
    admin: (groups ?? []).includes("gitboard-admin-users"),
    jwtToken: session.getIdToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
};

export const useLogout = () => {
  const navigate = useNavigate();
  return () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut(() => {
        navigate("/login");
      });
    }
  };
};

export const useDeleteUser = () => {
  const navigate = useNavigate();
  const user = useUser();
  const gitboardApiSdk = useGitboardApiSdk();
  return () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser && user) {
      gitboardApiSdk.deleteUser({ username: user.username });
      currentUser.signOut();
      navigate("/login");
    }
  };
};

const getSession = (user: CognitoUser) =>
  new Promise<CognitoUserSession>((resolve, reject) => {
    user.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error) {
        return reject(error);
      }
      return resolve(session);
    });
  });
const refreshSession = (user: CognitoUser, refreshToken: CognitoRefreshToken) =>
  new Promise<CognitoUserSession>((resolve, reject) => {
    user.refreshSession(
      refreshToken,
      (error: Error | null, session: CognitoUserSession) => {
        if (error) {
          return reject(error);
        }
        return resolve(session);
      },
    );
  });

export const useApplySession = () => {
  const setUser = useSetRecoilState(userState);
  const logout = useLogout();
  return async () => {
    try {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        const session = await getSession(cognitoUser);
        if (session && session.isValid()) {
          setUser(await extractUserFrom(session));
          return;
        }
      }
      setUser(undefined);
      return;
    } catch (error) {
      logout();
    }
  };
};

export function useRefreshSession() {
  const user = useRecoilValue(userState);
  const setUser = useSetRecoilState(userState);
  const logout = useLogout();
  const refresh = new CognitoRefreshToken({ RefreshToken: user!.refreshToken });
  return async () => {
    try {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        const session = await refreshSession(cognitoUser, refresh);
        if (session && session.isValid()) {
          setUser(await extractUserFrom(session));
          return;
        }
      }
      setUser(undefined);
      return;
    } catch (error) {
      debugger;
      logout();
    }
  };
}

export const useGetCognitoSession = (setErrors: Function) => {
  const applySession = useApplySession();
  return async (code: string) => {
    try {
      const response = await axios.request({
        url: `${authEndpoint}/oauth2/token`,
        method: "post",
        data: qs.stringify({
          grant_type: "authorization_code",
          client_id: userPool.getClientId(),
          redirect_uri: redirectUrl,
          code,
        }),
      });
      const idToken = new CognitoIdToken({ IdToken: response.data.id_token });
      new CognitoUser({
        Username: idToken.payload["cognito:username"],
        Pool: userPool,
      }).setSignInUserSession(
        new CognitoUserSession({
          IdToken: idToken,
          AccessToken: new CognitoAccessToken({
            AccessToken: response.data.access_token,
          }),
          RefreshToken: new CognitoRefreshToken({
            RefreshToken: response.data.refresh_token,
          }),
        }),
      );
      await applySession();
    } catch (error) {
      setErrors([error]);
    }
  };
};

export const useSession = () => {
  const applySession = useApplySession();

  useEffect(() => {
    const interval = setInterval(() => {
      applySession();
    }, 300000);

    return () => clearInterval(interval);
  }, []);
};

export const useUser = () => useRecoilValue(userState);
