import axios from 'axios';
import qs from 'query-string';
import { apiEndpoint } from '../environment';
import { Caller, GitboardApiSdk } from '@codeaim/gitboard-api';
import { useJwtToken } from './Authentication';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

export function useGitboardApiSdk() {
  const jwtToken = useJwtToken();
  return new GitboardApiSdk(authenticatedAxios(apiEndpoint, jwtToken));
}

export function useGitboardPublicApiSdk() {
  return new GitboardApiSdk(unauthenticatedAxios(apiEndpoint));
}

export function getGitboardApiSdk(jwtToken: string) {
  return new GitboardApiSdk(authenticatedAxios(apiEndpoint, jwtToken));
}
export function authenticatedAxios(url: string, jwtToken: string): Caller {
  return {
    call: async (
      method: any,
      resource: any,
      path: string,
      body: any,
      pathParameters: any,
      queryParameters: any,
      multiQueryParameters: any,
      headers: any,
      config: any,
    ) => {
      const updatedHeaders = {
        ...headers,
        Authorization: `Bearer ${jwtToken}`,
        'X-Session-Id': CryptoJS.MD5(jwtToken).toString(),
        'X-Correlation-Id': uuidv4(),
      };
      const result = await axios(url + path, {
        method: method as any,
        data: body,
        paramsSerializer: (params) => qs.stringify(params),
        params: { ...queryParameters, ...multiQueryParameters },
        headers: updatedHeaders,
        transformResponse: [],
        ...config,
      });
      return {
        statusCode: result.status,
        body: result.data,
        headers: result.headers as any,
      };
    },
  };
}

export function unauthenticatedAxios(url: string): Caller {
  return {
    call: async (
      method: any,
      resource: any,
      path: string,
      body: any,
      pathParameters: any,
      queryParameters: any,
      multiQueryParameters: any,
      headers: any,
      config: any,
    ) => {
      const updatedHeaders = {
        ...headers,
        'X-Correlation-Id': uuidv4(),
      };
      const result = await axios(url + path, {
        method: method as any,
        data: body,
        paramsSerializer: (params) => qs.stringify(params),
        params: { ...queryParameters, ...multiQueryParameters },
        headers: updatedHeaders,
        transformResponse: [],
        ...config,
      });
      return {
        statusCode: result.status,
        body: result.data,
        headers: result.headers as any,
      };
    },
  };
}
