import { useEffect } from "react";
import { getCLS, getFCP, getFID, getLCP, getTTFB, Metric } from "web-vitals";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { trackingId } from "../environment";
import { useAccountInfo } from "./Authentication";

function sendToGoogleAnalytics({ name, delta, value, id }: Metric) {
  ReactGA.send({
    hitType: "event",
    eventCategory: "Web Vitals",
    eventAction: name,
    eventLabel: id,
    nonInteraction: true,
    value: Math.round(name === "CLS" ? delta * 1000 : delta),
    metric_id: id,
    metric_value: value,
    metric_delta: delta,
  });
}

export const useAnalytics = () => {
  const { pathname, search } = useLocation();
  const account = useAccountInfo();
  ReactGA.initialize(trackingId, {
    gaOptions: { userId: account?.userAccount?.id },
  });
  getCLS(sendToGoogleAnalytics);
  getFCP(sendToGoogleAnalytics);
  getFID(sendToGoogleAnalytics);
  getLCP(sendToGoogleAnalytics);
  getTTFB(sendToGoogleAnalytics);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${pathname}${search}` });
  }, [pathname]);
};
