import { GitBoardUserServiceSdk, SubscriptionPlan, SubscriptionPlans } from '@gitboard-io/gitboard-user-service-sdk';
import { useRecoilValue } from 'recoil';
import { sdkSelector, useCall } from './sdks';

const accounts = sdkSelector(GitBoardUserServiceSdk, 'getAccounts', true);
const plans = sdkSelector(GitBoardUserServiceSdk, 'getPlans', false);

export function useCreateAccount(onComplete: () => void) { return useCall(GitBoardUserServiceSdk, 'createAccount', onComplete); }
export function useCreatePlanSession() { return useCall(GitBoardUserServiceSdk, 'createPlanSession'); }
export function useVerifyPlan() { return useCall(GitBoardUserServiceSdk, 'verifyPlan'); }

export function useAccounts() {
  return useRecoilValue(accounts.atom([{}]));
}

export function usePlans(): SubscriptionPlans {
  const subscriptionPlans = useRecoilValue(plans.atom([{}]));
  const free: SubscriptionPlan = {
    name: 'Free',
    description: 'Unlimited Public Repositories',
    id: '',
    amounts: [{
      id: '',
      amount: '0',
      currency: 'gbp',
      type: 'Monthly',
      isDefault: true
    },
      {
        id: '',
        amount: '0',
        currency: 'gbp',
        type: 'Yearly',
        isDefault: false
      }]}
  return {
    ...subscriptionPlans, plans: [free, ...subscriptionPlans.plans]
      .sort((a, b) => Math.max(...a.amounts.map(it => +it.amount)) - Math.max(...b.amounts.map(it => +it.amount)))
  }
}


