import React, { memo } from "react";
import isEqual from "lodash/isEqual";
import { Routes, Route } from "react-router-dom";
import { useAnalytics } from "../services/Analytics";

import Boundary from "./Boundary";
import LazyRetry from "./LazyRetry";

const Landing = React.lazy(() =>
  LazyRetry(() => import("../features/Landing")),
);
const Login = React.lazy(() => LazyRetry(() => import("../features/Login")));
const PrivacyPolicy = React.lazy(() =>
  LazyRetry(() => import("../features/PrivacyPolicy")),
);
const ServiceTerms = React.lazy(() =>
  LazyRetry(() => import("../features/ServiceTerms")),
);
const Navigation = React.lazy(() => LazyRetry(() => import("./Navigation")));
const NotFound = React.lazy(() =>
  LazyRetry(() => import("../features/NotFound")),
);
const Dashboard = React.lazy(() =>
  LazyRetry(() => import("../features/Dashboard")),
);
const DashboardSettings = React.lazy(() =>
  LazyRetry(() => import("../features/Settings/DashboardSettings")),
);
const Settings = React.lazy(() =>
  LazyRetry(() => import("../features/Settings")),
);
const Bitbucket = React.lazy(() =>
  LazyRetry(() => import("../features/Bitbucket")),
);
const Github = React.lazy(() => LazyRetry(() => import("../features/Github")));

const GithubConnect = React.lazy(() =>
  LazyRetry(() => import("../features/Github/Connect")),
);
const AccountSettings = React.lazy(() =>
  LazyRetry(() => import("../features/Settings/Account/AccountSettings")),
);
function RouteList() {
  useAnalytics();
  return (
    <Routes>
      <Route element={<Boundary />}>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<ServiceTerms />} />
        <Route element={<Navigation />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:account" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/verify" element={<Settings />} />
          <Route
            path="/settings/dashboard/:dashboard"
            element={<DashboardSettings />}
          />
          <Route path="/settings/:account" element={<AccountSettings />} />
          <Route path="/bitbucket" element={<Bitbucket />} />
          <Route path="/github/setup" element={<Github />} />
          <Route path="/github/connect" element={<GithubConnect />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default memo(RouteList, isEqual);
