import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { Alert, Box } from '@mui/material';

interface ErrorProps {
  error?: Error;
}

function ErrorFallback({ error }: ErrorProps) {
  return (
    <Box m={2}>
      <Alert variant="filled" severity="error">
        {error?.message}
      </Alert>
    </Box>
  );
}

export default memo(ErrorFallback, isEqual);
