import { createTheme, Shadows } from "@mui/material";

declare module "@mui/system/createTheme/createBreakpoints" {
  interface BreakpointOverrides {
    xxl: true;
    xxxl: true;
    xxxxl: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    brand: {
      github: string;
      facebook: string;
      google: string;
      contrastText: string;
    };
  }

  interface TypeBackground {
    paper2: string;
  }
  interface PaletteOptions {
    brand: {
      github: string;
      facebook: string;
      google: string;
      contrastText: string;
    };
  }
}

const theme = createTheme({
  shadows: [
    "none",
    "0 4px #00000040",
    ...(Array(25).fill("none") as string[]),
  ] as Shadows,
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 14,
    h5: {
      fontSize: 10,
      lineHeight: "10px",
    },
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 2000,
      xxl: 2400,
      xxxl: 2800,
      xxxxl: 3200,
    },
  },
  palette: {
    action: {
      disabledBackground: "#22272E",
      disabled: "#B2B2B2",
    },
    divider: "#FFFFFF22",
    primary: {
      main: "#7158E2",
      light: "#8D79E8",
      dark: "#5A46B5",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ED6C04",
      light: "#F18936",
      dark: "#BE5603",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#3AE374",
      light: "#61E990",
      dark: "#2EB65D",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#FF4D4D",
      light: "#FF7171",
      dark: "#CC3E3E",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#181C21",
      paper: "#22272E",
      paper2: "#1C2128",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#B2B2B2",
      disabled: "#727272",
    },
    brand: {
      github: "#5A46B5",
      facebook: "#316FF6",
      google: "#DB4437",
      contrastText: "#FFFFFF",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          "& > .MuiAlert-icon": {
            color: "#3AE374",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& input": {
            padding: "0.75em",
            color: "#939393",
          },
          "& input.Mui-disabled": {
            color: "#787878",
            WebkitTextFillColor: "#787878",
          },
        },
      },
    },
  },
});

export default theme;
