import { Outlet } from 'react-router-dom';
import React, { memo, Suspense } from 'react';
import isEqual from 'lodash/isEqual';
import Loading from './Loading';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Organization, WebSite } from 'schema-dts';
import icon from '../assets/image/icon.png';
import twitter from '../assets/image/twitter.jpeg';

function Boundary() {
  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp<Organization>({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'GitBoard.io - Builds made visible',
            url: 'https://gitboard.io',
            logo: icon,
            sameAs: [
              'https://www.facebook.com/tendernicom',
              'https://twitter.com/tendernicom',
              'https://www.linkedin.com/company/tender-northern-ireland',
            ],
          }),
          helmetJsonLdProp<WebSite>({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: 'GitBoard.io - Builds made visible',
            url: 'https://gitboard.io',
          }),
        ]}
      >
        <title>GitBoard.io - Builds made visible</title>
        <meta
          name="description"
          content="Keep informed with a real time build status dashboard for GitHub Actions. Easy-to-setup dashboard, include a single step in your workflow to track job status and view the build status of multiple workflows at a glance."
        />
        <meta
          property="og:site_name"
          content="GitBoard.io - Builds made visible"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@gitboardio" />
        <link rel="canonical" href="https://gitboard.io" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="GitBoard.io - Builds made visible"
        />
        <meta
          property="og:description"
          content="Keep informed with a real time build status dashboard for GitHub Actions. Easy-to-setup dashboard, include a single step in your workflow to track job status and view the build status of multiple workflows at a glance."
        />
        <meta property="og:url" content="https://gitboard.io" />
        <meta property="og:image" content={twitter} />
        <meta name="twitter:image:alt" content="GitBoard.io twitter banner" />
      </Helmet>
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorFallback error={error} />}
      >
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default memo(Boundary, isEqual);
