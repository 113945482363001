import { axiosSdkCaller } from "@hexlabs/axios-hexlabs-caller";
import { useCallback, useState } from "react";
import { atomFamily, RecoilState, selectorFamily } from "recoil";
import { environmentName } from "../environment";
import { jwtTokenQuery, useJwtToken } from "./Authentication";

type ClassFor<T> = { new (...args: any[]): T };

type SdkFunction<SDK> = Exclude<keyof SDK, "server">;

type SdkReturns<
  SDK,
  K extends keyof SDK,
  Expects extends number,
> = SDK[K] extends (...args: any) => Promise<infer R>
  ? R extends { statusCode: Expects; result: infer Result }
    ? Result
    : never
  : never;

type SdkParams<SDK, K extends keyof SDK> = SDK[K] extends (
  ...args: infer P
) => any
  ? P
  : never;

export type RecoilParts<
  SDK,
  K extends SdkFunction<SDK>,
  Expects extends number,
> = {
  atom: (
    params: [...SdkParams<SDK, K>],
  ) => RecoilState<SdkReturns<SDK, K, Expects>>;
  selector: (
    params: [...SdkParams<SDK, K>],
  ) => RecoilState<SdkReturns<SDK, K, Expects>>;
};

export function sdkSelector<SDK, K extends SdkFunction<SDK>>(
  sdk: ClassFor<SDK>,
  forFunction: K,
  authorized: boolean,
): RecoilParts<SDK, K, 200> {
  return sdkSelectorExpecting(sdk, forFunction, authorized, 200);
}
export function sdkSelectorExpecting<
  SDK,
  K extends SdkFunction<SDK>,
  Expects extends number,
>(
  sdk: ClassFor<SDK>,
  forFunction: K,
  authorized: boolean,
  expect: Expects,
): RecoilParts<SDK, K, Expects> {
  const selector = selectorFamily({
    get:
      (params: any) =>
      async ({ get }) => {
        const auth = authorized ? get(jwtTokenQuery) : undefined;
        const caller = new sdk(axiosSdkCaller(auth?.token), {
          environment: environmentName,
        });
        const result = await (caller[forFunction] as any)(...params);
        if (result.statusCode === expect) {
          return result.result;
        }
        throw new Error(
          `Got unexpected response from sdk ${forFunction.toString()}`,
        );
      },
    key: `${forFunction.toString()}Request`,
  });
  return {
    atom: atomFamily({
      key: `${forFunction.toString()}State`,
      default: selector,
    }),
    selector: selector as any,
  };
}

export interface CallStatus<T> {
  loading: boolean;
  failed: boolean;
  succeeded: boolean;
  started: boolean;
  result?: T;
}

export function useCall<SDK, K extends keyof SDK>(
  sdk: ClassFor<SDK>,
  call: K,
  onComplete?: SDK[K] extends (...args: any) => any
    ? (result: SdkReturns<SDK, K, 200>, ...params: Parameters<SDK[K]>) => void
    : () => void,
): SDK[K] extends (...args: any) => any
  ? {
      status: CallStatus<SdkReturns<SDK, K, 200>>;
      reset: () => void;
      trigger: (
        ...params: Parameters<SDK[K]>
      ) => Promise<SdkReturns<SDK, K, 200>>;
    }
  : never {
  const jwt = useJwtToken();
  const caller = new sdk(axiosSdkCaller(jwt), { environment: environmentName });
  const [status, setStatus] = useState<CallStatus<any>>({
    started: false,
    loading: false,
    failed: false,
    succeeded: false,
    result: undefined,
  });
  const trigger = useCallback(
    async (...params: any) => {
      setStatus({
        started: true,
        loading: true,
        succeeded: false,
        failed: false,
      });
      try {
        const result = await (caller[call] as any)(...params);
        if (result.statusCode >= 200 && result.statusCode <= 201) {
          setStatus({
            started: true,
            loading: false,
            succeeded: true,
            failed: false,
            result: result.result,
          });
          try {
            onComplete?.(result.result, ...params);
          } catch (e) {
            // do nothing
          }
        } else {
          setStatus({
            started: true,
            loading: false,
            succeeded: false,
            failed: true,
          });
        }
        return result.result;
      } catch (e) {
        setStatus({
          started: true,
          loading: false,
          succeeded: false,
          failed: true,
        });
      }
      return undefined;
    },
    [jwt],
  );
  return {
    status,
    trigger,
    reset: () =>
      setStatus({
        started: true,
        loading: false,
        failed: false,
        succeeded: false,
      }),
  } as any;
}
