import { EnvironmentBuilder } from "@hexlabs/env-vars-ts";
import { CognitoUserPool } from "amazon-cognito-identity-js";

export const environment = EnvironmentBuilder.create(
  "REACT_APP_ENVIRONMENT",
  "REACT_APP_WEBSOCKET_ENDPOINT",
  "REACT_APP_DOMAIN",
  "REACT_APP_USER_POOL_ID",
  "REACT_APP_APP_CLIENT_ID",
  "REACT_APP_DOMAIN",
  "REACT_APP_TRACKING_ID",
  "REACT_APP_GITHUB_APP_NAME",
  "REACT_APP_GITHUB_APP_CLIENT_ID",
  "REACT_APP_FEEDER_ID",
)
  .defaults({
    REACT_APP_ENVIRONMENT: "dev",
    REACT_APP_WEBSOCKET_ENDPOINT: "wss://sockets.dev.gitboard.io",
    REACT_APP_USER_POOL_ID: "eu-west-1_Dy5T9dGXy",
    REACT_APP_APP_CLIENT_ID: "3tfjnf5lo1hhh0ojpjngra8bp5",
    REACT_APP_DOMAIN: "dev.gitboard.io",
    REACT_APP_TRACKING_ID: "G-67QL6FBWF2",
    REACT_APP_GITHUB_APP_NAME: "gitboard-test",
    REACT_APP_GITHUB_APP_CLIENT_ID: "Iv1.bd4873cbc838c9cd",
    REACT_APP_FEEDER_ID: "65b4ef58986171000268e2a8",
  })
  .environment();

export const environmentName = environment.REACT_APP_ENVIRONMENT;
export const domain = environment.REACT_APP_DOMAIN;
export const githubAppName = environment.REACT_APP_GITHUB_APP_NAME;
export const redirectUrl = `https://${environment.REACT_APP_DOMAIN}/login`;
export const apiEndpoint = `https://api.${environment.REACT_APP_DOMAIN}`;
export const authEndpoint = `https://gitboard-${environmentName}.auth.eu-west-1.amazoncognito.com`;
export const trackingId = environment.REACT_APP_TRACKING_ID;

export const feederId = environment.REACT_APP_FEEDER_ID;

export const userPool = new CognitoUserPool({
  UserPoolId: environment.REACT_APP_USER_POOL_ID,
  ClientId: environment.REACT_APP_APP_CLIENT_ID,
});

export type LoginProvider = "Github" | "Google" | "Facebook";
export function loginUrlForProvider(provider: LoginProvider) {
  return `${authEndpoint}/oauth2/authorize?client_id=${userPool.getClientId()}&redirect_uri=${redirectUrl}&response_type=code&identity_provider=${provider}`;
}

export function githubAuthUrl(state: { account: string; installId?: string }) {
  const redirect = `https://${environment.REACT_APP_DOMAIN}/github/connect`;
  const encodedState = btoa(JSON.stringify(state));
  return `https://github.com/login/oauth/authorize?client_id=${environment.REACT_APP_GITHUB_APP_CLIENT_ID}&redirect_uri=${redirect}&state=${encodedState}`;
}
