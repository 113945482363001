import React from 'react';
import RouteList from './components/Routes';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RecoilRoot } from 'recoil';
import 'typeface-inter';
import theme from './theme';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <CssBaseline />
          <HelmetProvider>
            <RouteList />
          </HelmetProvider>
        </BrowserRouter>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>,
);
